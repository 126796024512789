export default {
  namespaced: true,
  state: {
    windowSize: {
      width: 0,
      height: 0,
    },
  },
  mutations: {
    setWindowSize(state, size) {
      state.windowSize = size;
    },
  },
};
