/**
 * Created by joachim on 04/04/2017.
 */

/**
 * @typedef {string} View The type contained in this enum
 * @name views
 * @readonly
 * @enum {View}
 */
const Views = {
  Blank: 'Blank', // This is only used when logging out, to unload other screens.

  // App views - do NOT change these without coordinating with Michael regarding SQL table contents
  AppLogin: 'AppLogin',
  MyPosts: 'MyPosts',
  MyDistrict: 'MyDistrict',
  AllPosts: 'AllPosts',
  Bookmarks: 'Bookmarks',
  MyProfile: 'MyProfile',
  VisitPlansApp: 'VisitPlansApp',
  OwnVisitPlanVisits: 'OwnVisitPlanVisits',
  AllVisitPlanVisits: 'AllVisitPlanVisits',

  // Admin views - do NOT change these without coordinating with Michael regarding SQL table contents
  AdminLogin: 'AdminLogin',
  Gallery: 'Gallery',
  AdminFeed: 'AdminFeed',
  Dashboard: 'Dashboard',
  Districts: 'Districts',
  Channels: 'Channels',
  Chains: 'Chains',
  Customers: 'Customers',
  Products: 'Products',
  Campaigns: 'Campaigns',
  VisitPlans: 'VisitPlans',
  Users: 'Users',
  Announcements: 'Announcements',
  ImportFiles: 'ImportFiles',
  SystemSettings: 'SystemSettings',
  InstallHelp: 'InstallHelp',
};
export default Views;
