import Cookies from 'js-cookie';
import { unregisterEventHandlersToVuex } from '@/classes/event-vuex-bridge';
import CookieNames from '../enums/cookie-names';

export default async function Logout() {
  Cookies.set(CookieNames.accessToken, '', { expires: -1 });
  await window.vuexStore.dispatch('closeMenu');
  window.vuexStore.commit('setAccessToken', null);
  await window.vuexStore.dispatch('resetAll');
  window.socketClient.destroySocket();

  window.vuexStore.commit('setUserData', null);
  unregisterEventHandlersToVuex();
}
