/**
 * These are the possible features available in the client.
 * Note that all string values must be camelCase.
 * @type {{}}
 */
export default {
  manualCustomerFilter: 'manualCustomerFilter',
  bookmarks: 'bookmarks',
  likes: 'likes',
  comments: 'comments',
  downloads: 'downloads',
  reports: 'reports',
  visitScore: 'visitScore',
  menuViewProducts: 'menuViewProducts',
  menuViewCampaigns: 'menuViewCampaigns',
  filterViewUserRoles: 'filterViewUserRoles',
  filterViewReactions: 'filterViewReactions',
  visitPlans: 'visitPlans',
  importFiles: 'importFiles',
};
