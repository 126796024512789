import { nextTick } from 'vue';
import { getCurrentPosition } from '@/classes/location-helper';

export default {
  namespaced: true,
  state: {
    // The user's current position. If present, it consists of lat and lng properties.
    location: null,
    isLoadingLocation: false,
    locationError: null,
    isGeolocationSupported: true, // That's what we believe until anything else is proven.
  },
  mutations: {
    setLocation(state, location) {
      state.location = location;
    },
    // Sets flag indicating if we are currently loading position.
    setIsLoadingLocation(state, isLoading) {
      state.isLoadingLocation = isLoading;
    },
    setLocationError(state, error) {
      state.locationError = error;
    },
    clearLocationError(state) {
      state.locationError = null;
    },
    setIsGeolocationSupported(state, isSupported) {
      state.isGeolocationSupported = isSupported;
    },
  },
  actions: {
    async requestLocation(context) {
      const { commit } = context;
      if (!navigator.geolocation) {
        commit('setIsGeolocationSupported', false);
        return;
      }

      commit('setIsLoadingLocation', true);

      // Give the ui time to update and show loading indicators
      await nextTick();

      try {
        const position = await getCurrentPosition();
        const coordinates = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
        commit('setLocation', coordinates);
        commit('setIsLoadingLocation', false);
      } catch (error) {
        console.warn('Error while trying to retrieve position: ', error);
        commit('setLocationError', error);
      } finally {
        commit('setIsLoadingLocation', false);
      }
      console.log('Location set.');
    },
  },
};
