// This module holds data and shared state for visit plan functionality in the app.
import { httpGet } from '@/classes/httpHelper';
import { getDistanceFromLatLongInKm } from '@/classes/location-helper';

export default {
  namespaced: true,
  state: {
    // This is the list of visit plans loaded for the current user.
    visitPlans: [],
    // This is used for pre-selecting the customer in Take Picture.
    preselectedCustomer: null,
    currentVisitPlan: null,
    // Flag that indicates that we are currently loading visit plans.
    isLoading: false,
    // We don't want to calculate distances all the time.
    // So we need to know where we were the previous time in order to determine when to
    // skip doing a new calculation.
    previousPositionForDistanceCalculation: null,
    /** Indicates that visit plans have been added, removed or updated.
     * This is not reloaded automatically, but happens when a button in the UI is pressed.
     */
    refreshAvailable: false,
  },
  getters: {
    // Indicates that the user is currently working from a visit plan.
    isVisitPlanState(state) {
      return state.currentVisitPlan != null;
    },
    isVisitInVisitPlan: (state) => (visitId) => {
      for (const visitPlan of state.visitPlans) {
        for (const customer of visitPlan.customers) {
          if (customer.visits && customer.visits.length) {
            for (const visit of customer.visits) {
              if (visit.id === visitId) {
                return true;
              }
            }
          }
        }
      }
      return false;
    },
    getVisitPlanById: (state) => (visitPlanId) => state.visitPlans.find((vp) => vp.id === visitPlanId),
  },
  mutations: {
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setVisitPlans(state, visitPlans) {
      state.visitPlans = visitPlans;
    },
    setCurrentVisitPlan(state, visitPlan) {
      state.currentVisitPlan = visitPlan;
    },
    clearCurrentVisitPlan(state) {
      state.currentVisitPlan = null;
    },
    setPreselectedCustomer(state, customer) {
      state.preselectedCustomer = customer;
    },
    clearPreselectedCustomer(state) {
      state.preselectedCustomer = null;
    },
    markCustomerVisited(state, visit) {
      const visitedCustomer = visit.customer[0];
      for (const visitPlan of state.visitPlans) {
        const customerIndex = visitPlan.customers.findIndex((c) => c.id === visitedCustomer.id);
        if (customerIndex > -1) {
          const customer = visitPlan.customers[customerIndex];
          if (!customer.visits) {
            customer.visits = [];
          }
          customer.visits.push(visit);
        }
      }
    },
    markVisitDeleted(state, visitId) {
      for (const visitPlan of state.visitPlans) {
        for (const customer of visitPlan.customers) {
          if (!customer.visits || customer.visits.length === 0) continue;
          const index = customer.visits.findIndex((v) => v.id === visitId);
          if (index > -1) {
            customer.visits.splice(index, 1);
            console.log(`VisitPlan with id ${visitId} was removed from customer ${
              customer.name} on visit plan ${visitPlan.name}.`);
          }
        }
      }
    },
    // Calculates distance for all customers based on current position
    calculateCustomerDistance(state, position) {
      if (position == null) {
        console.warn('CalculateCustomerDistance: position was null.');
        return;
      }
      if (state.previousPositionForDistanceCalculation != null) {
        const distanceFromLastTime = getDistanceFromLatLongInKm(state.previousPositionForDistanceCalculation, position);
        if (distanceFromLastTime < 1) return;
      }
      for (const visitPlan of state.visitPlans) {
        for (let i = 0; i < visitPlan.customers.length; i += 1) {
          const customer = visitPlan.customers[i];
          const customerPosition = {
            latitude: customer.latitude,
            longitude: customer.longitude,
          };
          visitPlan.customers[i].distance = getDistanceFromLatLongInKm(customerPosition, position);
        }
      }
      state.previousPositionForDistanceCalculation = position;
    },
    markRefreshAvailable(state) {
      state.refreshAvailable = true;
    },
    markRefreshCompleted(state) {
      state.refreshAvailable = false;
    },
  },
  actions: {
    async loadVisitPlansForUser(context) {
      const { commit, rootState } = context;
      const userId = rootState.user.id;
      commit('setIsLoading', true);

      const visitPlans = await httpGet(`visit-plans/for-user/${userId}`);
      commit('setVisitPlans', visitPlans);
      commit('markRefreshCompleted');
      commit('setIsLoading', false);
    },
    clearVisitPlans(context) {
      const { commit } = context;
      commit('setVisitPlans', []);
      commit('clearCurrentVisitPlan');
      commit('clearPreselectedCustomer');
    },
  },
};
