/**
 * This mixin adds a method called "x" to the mixing vue component, which provides a localized
 * phrase. The actual phrase library is loaded in bootstrap.js and added to vuex store, from where
 * it is accessed here.
 */
export default {
  methods: {
    /** Translates a phrase using the current translation table,
     * which must be present as 'this.config.translation'. */
    x(s) {
      if (s === '' || s == null) return '';
      return this.$store.state.config.translation[s] || `MISSING TRANSLATION: '${s}'`;
    },
  },
};
