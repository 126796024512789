/**
 * Converts between degrees and radians.
 * @param deg
 * @returns {number}
 */
function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

/**
 * Calculates the distance between two locations and returns the distance in kilometer.
 * @param position1
 * @param position2
 * @return {number} Distance in km
 */
export function getDistanceFromLatLongInKm(position1, position2) {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(position2.latitude - position1.latitude); // deg2rad below
  const dLon = deg2rad(position2.longitude - position1.longitude);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
    + Math.cos(deg2rad(position1.latitude)) * Math.cos(deg2rad(position2.latitude))
    * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  // Distance in km
  return R * c;
}

/**
 * Wrapping geolocation api with promise.
 * @returns {Promise<unknown>}
 */
export function getCurrentPosition() {
  if (navigator.geolocation) {
    const options = { timeout: 15000 };
    return new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject, options));
  }

  return new Promise((resolve, reject) => reject(new Error('Geolocation not available.')));
}
