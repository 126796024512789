/**
 * Checks for unread announcements
 */
import { defineAsyncComponent } from 'vue';
import { httpGet } from './httpHelper';

export default async function checkForUnreadAnnouncements() {
  const announcements = await httpGet('users/me/announcements');

  if (announcements.length > 0) {
    console.log('Got announcements. Popup should be shown.');
    const AnnouncementDetails = defineAsyncComponent(() => import('../components/Announcements/AnnouncementDetails'));
    vuexStore.commit('pushPopup', {
      title: window.vuexStore.state.config.translation.headlineAnnouncements,
      component: AnnouncementDetails,
      direction: 'up',
      params: {
        announcement: announcements[0],
      },
    });
  } else {
    console.debug('No unread announcements.');
  }
}
