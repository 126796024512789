export default {
  namespaced: true,
  state: {
    /**
     * If this is set, then there is a current survey that is being performed by users
     * right now.
     */
    currentSurvey: null,
    /**
     * Contains the surveys that are available to the current user.
     */
    availableSurveys: [],
    /**
    Contains schema definitions for surveys loaded previously in this session.
    Especially used for showing visits from previous surveys.
    Key: Survey template ID
     */
    cache: {},
    /**
     * If a visit is loaded that contains a survey, then this holds the schema and answers
     * for that visit. This might be a different survey from the current one, if the loaded
     * visit was created with a previous survey.
     */
    loadedSurvey: null,
    /**
     * If a survey has not been correctly set up, the error message can be put in here and will
     * be shown instead of the Take Picture screen.
     */
    surveyErrorMessage: null,
  },
  getters: {
    surveyCount: (state) => state.availableSurveys.length,
  },
  mutations: {
    reset(state) {
      state.currentSurvey = null;
      state.availableSurveys = [];
      state.cache = {};
      state.loadedSurvey = null;
      state.surveyErrorMessage = null;
    },
    setCurrentSurvey(state, survey) {
      console.log('Setting current survey');
      if (Array.isArray(survey)) throw new Error('Vuex.SetSurvey: Survey should not be an array, but a single survey.');
      state.currentSurvey = survey;
      if (!(survey.id in state.cache)) {
        state.cache[survey.id] = survey;
      }
    },
    /**
     * Stores the schemas for the surveys available for the user. If only a single survey
     * is available, the currentSurvey property is also set.
     */
    setAvailableSurveys(state, surveys) {
      state.availableSurveys = surveys;
      if (surveys.length === 1) {
        state.currentSurvey = surveys[0];
      }
    },
    setLoadedSurvey(state, survey) {
      state.loadedSurvey = survey;
    },
    clearLoadedSurvey(state) {
      state.loadedSurvey = null;
    },
    setSurveyErrorMessage(state, message) {
      state.surveyErrorMessage = message;
    },
  },
};
