// noinspection JSUnusedGlobalSymbols
export default {
  namespaced: true,
  state: {
    selectedForDownload: {},
    isLoading: false,
    downloadUIVisible: false,
  },
  mutations: {
    reset(state) {
      state.selectedForDownload = {};
      state.isLoading = false;
      state.downloadUIVisible = false;
    },
    toggleDownloadVisible(state) {
      state.downloadUIVisible = !state.downloadUIVisible;
    },
    setSelectedForDownload(state, obj) {
      state.selectedForDownload = obj;
    },
    selectImageForDownload(state, imageInfo) {
      if (imageInfo.visitId in state.selectedForDownload) {
        state.selectedForDownload[imageInfo.visitId].push(imageInfo.imageName);
      } else {
        // TODO: TEST VUE3
        state.selectedForDownload[imageInfo.visitId] = [];
        // Vue.set(state.selectedForDownload, imageInfo.visitId, []);
        state.selectedForDownload[imageInfo.visitId].push(imageInfo.imageName);
      }
    },
    deselectImageForDownload(state, imageInfo) {
      if (!state.selectedForDownload[imageInfo.visitId]) return;
      if (state.selectedForDownload[imageInfo.visitId].length === 1) {
        // TODO: TEST VUE3
        delete state.selectedForDownload[imageInfo.visitId];
        // Vue.delete(state.selectedForDownload, imageInfo.visitId);
      } else {
        const idx = state.selectedForDownload[imageInfo.visitId].indexOf(imageInfo.imageName);
        state.selectedForDownload[imageInfo.visitId].splice(idx, 1);
      }
    },
    deselectAllImages(state) {
      console.log('vuex: Deselect all');
      state.selectedForDownload = {};
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
  },
  actions: {
    selectAllImages({ commit, rootState }) {
      const list = {};
      rootState.galleryItems.forEach((item) => {
        if (item.pictures) {
          list[item.id] = item.pictures.map((picture) => picture.name);
        }
      });
      commit('setSelectedForDownload', list);
    },
  },
};
