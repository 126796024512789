<template>
  <i class="fa" :class="$attrs.name + ' ' + ($data.staticClass || '')"/>
</template>

<script>
  /**
   * REGISTERED GLOBALLY IN BOOTSTRAP.JS
   * Makes a FontAwesome 4 icon. Icon name should be in the 'name' attribute of the tag.
   */
  export default {
    name: 'icon',
  };
</script>
