import Logout from './logout';

/**
 * Creates options object for jQuery ajax call
 */
function makeOpts(method, url, errorFn, successFn, addBaseUrl = true) {
  const opts = {
    method,
    contentType: 'application/json',
    url: (addBaseUrl ? window.vuexStore.state.config.apiBaseUrl : '') + url,
    success: successFn,
    error: errorFn,
  };
  if (window.vuexStore && window.vuexStore.state.accessToken) {
    opts.headers = {
      Authorization: `Bearer ${window.vuexStore.state.accessToken}`,
    };
  }
  return opts;
}

async function checkForUnauthorized(errorText) {
  if (errorText.toLowerCase() === 'unauthorized') {
    await Logout();
  }
}

/**
 *
 * @param {string} url
 * @returns {Promise.<*>}
 */
export async function httpGet(url) {
  return new Promise((resolve, reject) => {
    $.ajax(makeOpts('get', url, async (x, t, errorText) => {
      await checkForUnauthorized(errorText);
      reject(errorText);
    }, (data) => {
      if ('error' in data) {
        reject(data);
      } else {
        resolve(data);
      }
    }));
  });
}

/**
 *
 * @param {string} url
 * @param {Object} data
 * @returns {Promise.<*>}
 */
export async function httpPut(url, data) {
  return new Promise((resolve, reject) => {
    const opts = makeOpts('put', url, async (x, t, errorText) => {
      await checkForUnauthorized(errorText);
      reject(errorText);
    }, (returnData) => {
      if ('error' in returnData) {
        reject(returnData);
      } else {
        resolve(returnData);
      }
    });
    opts.data = (typeof data === 'string') ? data : JSON.stringify(data);
    $.ajax(opts);
  });
}

/**
 *
 * @param {string} url
 * @param {Object} data
 * @returns {Promise.<*>}
 */
export async function httpPost(url, data) {
  return new Promise((resolve, reject) => {
    const opts = makeOpts('post', url, async (x, t, errorText) => {
      await checkForUnauthorized(errorText);
      reject(errorText);
    }, (returnData) => {
      if ('error' in returnData) {
        reject(returnData);
      } else {
        resolve(returnData);
      }
    });
    opts.data = (typeof data === 'string') ? data : JSON.stringify(data);
    $.ajax(opts);
  });
}

/**
 *
 * @param {string} url
 * @returns {Promise.<*>}
 */
export async function httpDelete(url) {
  return new Promise((resolve, reject) => {
    $.ajax(makeOpts('delete', url, async (x, t, errorText) => {
      await checkForUnauthorized(errorText);
      reject(errorText);
    }, (data) => {
      if ('error' in data) {
        reject(data);
      } else {
        resolve(data);
      }
    }));
  });
}
