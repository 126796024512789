import { httpGet, httpPost } from '@/classes/httpHelper';
import Views from '@/enums/views';

function getUrl(type, filter = null) {
  switch (type) {
    case Views.MyPosts:
      return filter == null ? 'user/me/visits' : 'visits/filter'; // filter will include user filter
    case Views.AllPosts:
      return filter == null ? 'visits' : 'visits/filter';
    case Views.MyDistrict:
      return 'user/me/visitsinregion';
    case Views.Bookmarks:
      return 'user/me/visits/bookmarked';
    case Views.OwnVisitPlanVisits:
      return 'visitplan/ownvisits';
    case Views.AllVisitPlanVisits:
      return 'visitplan/allvisits';
    default:
      return 'user/me/visits';
  }
}

export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    currentPage(state, getters, rootState) {
      return rootState.currentPage;
    },
  },
  mutations: {
    reset(state) {
      state.items = [];
    },
    updateItem(state, item) {
      const index = state.items.findIndex((i) => i.id === item.id);
      if (index !== -1) {
        state.items.splice(index, 1, item);
      }
    },
    deleteItem(state, itemId) {
      state.items = state.items.filter((i) => i.id !== itemId);
    },
    setItems(state, items) {
      state.items = items;
    },
    increaseCommentCounter(state, index) {
      state.items[index].countComments += 1;
    },
    decreaseCommentCounter(state, index) {
      state.items[index].countComments -= 1;
    },
    increaseLikeCounter(state, index) {
      state.items[index].countLikes += 1;
    },
    decreaseLikeCounter(state, index) {
      state.items[index].countLikes -= 1;
    },
    /** Marks a visit as liked by the current user. */
    setVisitLiked(state, index) {
      state.items[index].Liked = true;
    },
    /** Marks a visit as not liked by the current user. */
    unsetVisitLiked(state, index) {
      state.items[index].Liked = false;
    },
  },
  actions: {
    setItemComment(context, visitId) {
      const index = context.state.items.findIndex((i) => i.id === visitId);
      if (index >= 0) {
        context.commit('increaseCommentCounter', index);
      }
    },
    removeItemComment(context, visitId) {
      const index = context.state.items.findIndex((i) => i.id === visitId);
      if (index >= 0) {
        context.commit('decreaseCommentCounter', index);
      }
    },
    /**
     * Set one additional like for a visit. If the user performing the like is the current user,
     * set the "Liked" property of the visit to true.
     * @param {Object} context The vuex context
     * @param {string} visitId The visit being liked
     * @param {string} userId The user performing the like.
     */
    setItemLiked(context, { visitId, userId }) {
      const index = context.state.items.findIndex((i) => i.id === visitId);
      if (index >= 0) {
        context.commit('increaseLikeCounter', index);
      }
      if (userId === context.rootState.user.id) {
        context.commit('setVisitLiked', index);
      }
    },
    /**
     * Set one less like for a visit. If the user performing the like is the current user,
     * set the "Liked" property of the visit to false.
     * @param {Object} context The vuex context
     * @param {string} visitId The visit being unliked
     * @param {string} userId The user performing the unlike.
     */
    unsetItemLiked(context, { visitId, userId }) {
      const index = context.state.items.findIndex((i) => i.id === visitId);
      if (index >= 0) {
        context.commit('decreaseLikeCounter', index);
      }
      if (userId === context.rootState.user.id) {
        context.commit('unsetVisitLiked', index);
      }
    },
    updateCustomer(context, customer) {
      const visits = context.state.items.filter((v) => v.customerId === customer.id);
      for (const visit of visits) {
        // TODO: TEST VUE3
        visit.customer = [customer];
        // Vue.set(visit, 'customer', [customer]);
      }
      console.log(visits);
    },
    async loadFeed(context, type) {
      const baseUrl = getUrl(type);
      const url = `${baseUrl}?rand=${new Date().getTime()}&userrole=${context.rootState.user.userRole}`;
      const items = await httpGet(url);
      context.commit('setItems', items);
    },
    // TODO: MOVE FILTERS INTO THIS MODULE
    async loadFeedFiltered(context, { filter, type }) {
      const baseUrl = getUrl(type, filter);
      const items = await httpPost(baseUrl, filter);
      context.commit('setItems', items);
    },
    /**
     *
     * @param {Object} context
     * @param {Visit} visit
     */
    insertItem(context, visit) {
      const currentPage = context.getters.currentPage;
      let shouldInsertItem = true;
      const userId = context.rootState.user.id;
      const userDistrictId = context.rootState.user.districtId;
      let districtIdOfPost;

      switch (currentPage) {
        case Views.MyPosts:
          if (visit.userId !== userId) {
            shouldInsertItem = false;
          }
          break;
        case Views.MyDistrict:
          // District: Posts should be inserted if their customer has a district that is the
          // same as the district of the user.
          if (!Array.isArray(visit.customer)) {
            shouldInsertItem = false;
          }
          if (visit.customer.length === 0) {
            shouldInsertItem = false;
          }
          districtIdOfPost = visit.customer[0].districtId;
          if (districtIdOfPost !== userDistrictId) {
            shouldInsertItem = false;
          }
          break;
        default:
          break;
      }

      if (shouldInsertItem) {
        console.debug('Inserting visit at the beginning of the feed item list.');
        context.state.items.splice(0, 0, visit);
      }
    },
  },
};
