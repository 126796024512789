/**
 * This store takes care of online/offline status for the browser.
 */
export default {
  namespaced: true,
  state: {
    // This property reflects if the browser is online or not. It is controlled by window events.
    // But the app can be set to offline mode even if the browser is online.
    browserOnline: true,
    // This property is the "actual" state of the app. It can depend on the browser online mode,
    // but also if the app has manually been set to offline mode.
    online: true,
    // Indicates that the app state is manually controlled. If not, it follows the browser state.
    isManual: false,
    // This is used for indicating progress when refreshing caches etc. for offline usage. Value is 0 to 1.
    prepareForOfflineProgress: 0,
  },
  mutations: {
    /**
     * @param {object} state
     * @param {boolean} value
     */
    setBrowserOnline(state, value) {
      console.log(`Browser online state set to ${value}`);
      state.browserOnline = value;
      if (!state.isManual) {
        console.log(`App online state set to ${value}`);
        state.online = value;
      }
    },
    setOnline(state, value) {
      console.log(`App online state set to ${value}`);
      state.online = value;
    },
  },
  actions: {
    initialize({ commit }) {
      const online = navigator.onLine;
      commit('setBrowserOnline', online);
      commit('setOnline', online);
      window.addEventListener('online', () => commit('setBrowserOnline', true));
      window.addEventListener('offline', () => commit('setBrowserOnline', false));
    },
    prepareForOffline(context) {
      console.log('Prepare for offline. ', context);
    },
  },
};
