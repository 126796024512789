/* eslint-disable max-len */
import Events from '@/enums/event-names';
import CacheNames from '@/enums/cache-names';
import ActionNames from '@/enums/action-names';

const payload = (dataType, action, element) => ({ dataType, action, element });

function deleteCustomer(id) { window.vuexStore.commit('CachingStore/updateCache', payload(CacheNames.customers, ActionNames.delete, id)); }
function updateCustomer(customer) { window.vuexStore.commit('CachingStore/updateCache', payload(CacheNames.customers, ActionNames.update, customer)); }
function createCustomer(customer) { window.vuexStore.commit('CachingStore/updateCache', payload(CacheNames.customers, ActionNames.create, customer)); }

function updateCustomerImages(customerIdAndPictures) { window.vuexStore.commit('CachingStore/updateCustomerImages', customerIdAndPictures); }

function deleteChain(id) { window.vuexStore.commit('CachingStore/updateCache', payload(CacheNames.chains, ActionNames.delete, id)); }
function updateChain(chain) { window.vuexStore.commit('CachingStore/updateCache', payload(CacheNames.chains, ActionNames.update, chain)); }
function createChain(chain) { window.vuexStore.commit('CachingStore/updateCache', payload(CacheNames.chains, ActionNames.create, chain)); }

function deleteCampaign(id) { window.vuexStore.commit('CachingStore/updateCache', payload(CacheNames.campaigns, ActionNames.delete, id)); }
function updateCampaign(campaign) { window.vuexStore.commit('CachingStore/updateCache', payload(CacheNames.campaigns, ActionNames.update, campaign)); }
function createCampaign(campaign) { window.vuexStore.commit('CachingStore/updateCache', payload(CacheNames.campaigns, ActionNames.create, campaign)); }

function deleteChannel(id) { window.vuexStore.commit('CachingStore/updateCache', payload(CacheNames.channels, ActionNames.delete, id)); }
function updateChannel(channel) { window.vuexStore.commit('CachingStore/updateCache', payload(CacheNames.channels, ActionNames.update, channel)); }
function createChannel(channel) { window.vuexStore.commit('CachingStore/updateCache', payload(CacheNames.channels, ActionNames.create, channel)); }

function deleteProduct(id) { window.vuexStore.commit('CachingStore/updateCache', payload(CacheNames.products, ActionNames.delete, id)); }
function updateProduct(product) { window.vuexStore.commit('CachingStore/updateCache', payload(CacheNames.products, ActionNames.update, product)); }
function createProduct(product) { window.vuexStore.commit('CachingStore/updateCache', payload(CacheNames.products, ActionNames.create, product)); }

function createVisit(visit) {
  console.log('Received CreateVisit event. Data: ', visit);
  window.vuexStore.dispatch('FeedStore/insertItem', visit);
  window.vuexStore.commit('VisitPlanStore/markCustomerVisited', visit);
}
function updateVisit(visit) { window.vuexStore.commit('FeedStore/updateItem', visit); }
function deleteVisit(visitId) {
  window.vuexStore.commit('FeedStore/deleteItem', visitId);
  window.vuexStore.commit('VisitPlanStore/markVisitDeleted', visitId);
  window.vuexStore.commit('removeItemFromGallery', visitId);
}

function visitLiked() { window.vuexStore.commit('increaseUnreadNotifications'); }
function resetNotificationCount() { window.vuexStore.commit('markAllNotificationsRead'); }
async function markVisitPlanUpdateAvailable() { await window.vuexStore.commit('VisitPlanStore/markRefreshAvailable'); }

const eventsAndHandlers = {
  [Events.customerDeleted]: deleteCustomer,
  [Events.customerUpdated]: updateCustomer,
  [Events.customerCreated]: createCustomer,
  [Events.customerPicturesUpdated]: updateCustomerImages,
  [Events.chainDeleted]: deleteChain,
  [Events.chainUpdated]: updateChain,
  [Events.chainCreated]: createChain,
  [Events.campaignDeleted]: deleteCampaign,
  [Events.campaignUpdated]: updateCampaign,
  [Events.campaignCreated]: createCampaign,
  [Events.productDeleted]: deleteProduct,
  [Events.productUpdated]: updateProduct,
  [Events.productCreated]: createProduct,
  [Events.channelDeleted]: deleteChannel,
  [Events.channelUpdated]: updateChannel,
  [Events.channelCreated]: createChannel,
  [Events.visitCreated]: createVisit,
  [Events.visitUpdated]: updateVisit,
  [Events.visitDeleted]: deleteVisit,
  [Events.visitPlanCreated]: markVisitPlanUpdateAvailable,
  [Events.visitPlanUpdated]: markVisitPlanUpdateAvailable,
  [Events.visitPlanDeleted]: markVisitPlanUpdateAvailable,
  [Events.visitLiked]: visitLiked,
  [Events.resetNotificationCount]: resetNotificationCount,
};

/**
 * Makes event handlers for socket events that update the vuex cache.
 */
export function registerEventHandlersToVuex() {
  for (const [key, value] of Object.entries(eventsAndHandlers)) {
    // key is event name, value is handler function
    window.EventBus.on(key, value);
  }
}

export function unregisterEventHandlersToVuex() {
  for (const [key, value] of Object.entries(eventsAndHandlers)) {
    // key is event name, value is handler function
    window.EventBus.off(key, value);
  }
}
