/**
 * Produces a clean object for editorData.
 * @returns {{item: {}, dataSchema: {}, popupId: string, datatype: null, rowComponent: null, id: string}}
 */
function getCleanEditorData() {
  return {
    datatype: null, // The type of data being edited
    item: {}, // Data in the fields being edited.
    dataSchema: [], // Editor field definitions
    rowComponent: null, // A reference to the component loading the editor
    popupId: '', // Css-id of the popup html element
    id: '', // Id of current announcement being loaded. Blank if new.
  };
}

export default {
  namespaced: true,
  state: {
    isLoading: false,
    showsDataEditor: false,
    editorData: getCleanEditorData(),
  },
  mutations: {
    reset(state) {
      state.isLoading = false;
      state.showsDataEditor = false;
      state.editorData = getCleanEditorData();
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    closeEditor(state) {
      state.showsDataEditor = false;
    },
    clearEditorData(state) {
      state.editorData = getCleanEditorData();
    },
    setEditorData(state, data) {
      state.editorData = data;
      state.showsDataEditor = true;
    },
  },
};
