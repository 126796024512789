/**
 * These are the events that can be broadcast from the server OR emitted by the client.
 * We reuse the names.
 * @type {{}}
 */
export default {
  visitCreated: 'visit-created',
  visitDeleted: 'visit-deleted',
  visitUpdated: 'visit-updated',
  visitLiked: 'visit-liked',
  visitUnliked: 'visit-unliked',
  visitCommentAdded: 'visit-comment-added',
  visitCommentRemoved: 'visit-comment-removed',

  notification: 'notification',
  notificationDeleted: 'notification-deleted',

  resetFilter: 'reset-filter',
  takePicture: 'take-picture',

  receivedNotification: 'received-notification',
  resetNotificationCount: 'reset-notification-count',

  customerCreated: 'customer-created',
  customerUpdated: 'customer-updated',
  customerDeleted: 'customer-deleted',
  // This is an array that is never created or deleted, only updated.
  customerPicturesUpdated: 'customer-pictures-updated',
  chainCreated: 'chain-created',
  chainUpdated: 'chain-updated',
  chainDeleted: 'chain-deleted',
  campaignCreated: 'campaign-created',
  campaignUpdated: 'campaign-updated',
  campaignDeleted: 'campaign-deleted',
  productCreated: 'product-created',
  productUpdated: 'product-updated',
  productDeleted: 'product-deleted',
  channelCreated: 'channel-created',
  channelUpdated: 'channel-updated',
  channelDeleted: 'channel-deleted',
  visitPlanCreated: 'visit-plan-created',
  visitPlanUpdated: 'visit-plan-updated',
  visitPlanDeleted: 'visit-plan-deleted',
};
