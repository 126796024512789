import { createApp } from 'vue';
import Mitt from 'mitt';
import { camelCase } from 'lodash';
import Cookies from 'js-cookie';
import Icon from '@/components/Icon';
import LoginComponent from '@/components/Login/Login';
import IsEmpty from '@/components/Mixins/IsEmpty';
import { registerEventHandlersToVuex } from '@/classes/event-vuex-bridge';
import EventNames from '@/enums/event-names';
import { httpGet } from './httpHelper';
import createVuex from '../vuex/vuex-store';
import SocketClient from './SocketClient';
import Login from './login';
import { createEventHandlers } from './event-handlers';
import Views from '../enums/views';
import CookieNames from '../enums/cookie-names';
import setupVueRouter from './router';
import checkForUnreadAnnouncements from './AnnouncementHelper';

// Stuff that is loaded when the app loads.
export default class Bootstrap {
  /** The root app instance */
  app;

  /** The vue app configuration being set up through this class,
   * which in the end is used to instantiate the root app instance. */
  appDefinition;

  vuexStore;

  async boot() {
    const mode = $('body').hasClass('user') ? 'app' : 'admin';
    this.createVuexStore(mode);
    this.initializeOnlineStateDetection();
    this.enablePassiveJQueryEventListeners();
    this.createVueRoot();
    this.setVueDevToolsExplicitly();
    await this.getPublicProperties();
    await this.getTheme();
    this.setFeatures(vuexStore.state.config.publicProperties);
    const isLoggedIn = await Login.checkUserLogin();
    await this.getTranslation(vuexStore.getters.language);
    this.registerGlobalVueComponents();
    this.createEventBus();
    this.mountApp();

    if (isLoggedIn) {
      await this.trueUserLogin();
    }
  }

  createVuexStore(mode) {
    // Store: vuex store holding global config.
    // Stored in global space in order to be accessible outside vue as well.
    this.vuexStore = createVuex({
      apiBaseUrl: `${window.location.protocol}//${window.location.host}/api/v1/`,
      language: 'da',
      publicProperties: {},
      features: {},
      mode,
      buildMode: process.env.NODE_ENV,
    });
    window.vuexStore = this.vuexStore;
  }

  initializeOnlineStateDetection() {
    this.vuexStore.dispatch('OfflineStore/initialize');
  }

  enablePassiveJQueryEventListeners() {
    jQuery.event.special.touchstart = {
      setup(_, ns, handle) {
        this.addEventListener('touchstart', handle, { passive: !ns.includes('noPreventDefault') });
      },
    };
    jQuery.event.special.touchmove = {
      setup(_, ns, handle) {
        this.addEventListener('touchmove', handle, { passive: !ns.includes('noPreventDefault') });
      },
    };
    jQuery.event.special.wheel = {
      setup(_, ns, handle) {
        this.addEventListener('wheel', handle, { passive: true });
      },
    };
    jQuery.event.special.mousewheel = {
      setup(_, ns, handle) {
        this.addEventListener('mousewheel', handle, { passive: true });
      },
    };
  }

  createVueRoot() {
    // TODO: TEST VUE3
    const router = setupVueRouter();

    this.appDefinition = createApp({
      components: {
        Login: LoginComponent,
      },
      template: '<Login ref="login" />',
      // This root instance is used for emitting events that other components can listen to.
      // I suspect that it is a hack, and that it can be replaced by the event bus or by
      // feeding events into vuex as commands.
      // Anyway, currently it supports emitting whatever is in the EventNames enum.
      emits: Object.values(EventNames),
    })
      .use(this.vuexStore)
      .use(router);
  }

  /**
   * This should be run after initialization and configuration of the app has finished,
   * to mount into the DOM structure.
   */
  mountApp() {
    this.app = this.appDefinition.mount('#vueContainer');
    window.globalVue = this.app;
  }

  /**
   * Global event bus for sending messages across the app, components and vuex,
   * and also for receiving messages from the server (via socket.io) and sending
   * them into the system.
   */
  createEventBus() {
    const eventBus = new Mitt();
    this.appDefinition.config.globalProperties.$bus = eventBus;
    window.EventBus = eventBus;
  }

  setVueDevToolsExplicitly() {
    if (window.vuexStore.state.config.buildMode === 'development') {
      // Dev mode
      this.appDefinition.config.devtools = true;
      console.debug('Set devtools to true');
    }
  }

  /**
   * Registers base vue components, i.e. components that are widely used and easily enough
   * understood that it is not necessary to include them explicitly in other components.
   */
  registerGlobalVueComponents() {
    this.appDefinition.component('icon', Icon);
    this.appDefinition.mixin(IsEmpty);
  }

  async getPublicProperties() {
    const data = await httpGet('properties/public/simple');

    vuexStore.commit('setConfigProperties', { publicProperties: data });
    if (data.DefaultLanguage) {
      vuexStore.commit('setConfigProperties', { DefaultLanguage: data.DefaultLanguage });
    }
    vuexStore.commit('markLoaded', { publicProperties: true });
  }

  async getTheme() {
    const themeData = await httpGet('theme');

    const root = document.documentElement;

    Object.entries(themeData).forEach(([key, value]) => {
      // css variables always have to be named starting with two dashes.
      const cssVarName = `--${key}`;
      root.style.setProperty(cssVarName, value.value);
    });
  }

  setFeatures(obj) {
    const features = {};
    Object.keys(obj).forEach((prop) => {
      if (prop.toLowerCase().startsWith('feature.')) {
        const name = camelCase(prop.substr('feature.'.length));
        features[name] = obj[prop];
      }
    });
    vuexStore.commit('setFeatures', features);
  }

  async getTranslation(language) {
    const translation = await httpGet(`translations/${language}`);
    vuexStore.commit('setTranslation', {
      language,
      translation,
    });
  }

  /**
   * This is stuff that has to be done when the user is logged in,
   * either at app startup or after successful login.
   * @returns {Promise.<void>}
   */
  async trueUserLogin() {
    this.createSocketClient(vuexStore.state.config.mode);

    // noinspection JSIgnoredPromiseFromCall
    await this.getProperties();
    this.setupCloudinary();
    switch (vuexStore.state.config.mode) {
      case 'app':
        await this.setupApp();
        break;

      case 'admin':
        await this.setupAdmin();
        break;

      default:
        console.error(`unknown app state: ${vuexStore.state.config.mode}`);
        break;
    }

    await checkForUnreadAnnouncements();
  }

  setupCloudinary() {
    $.cloudinary.config({
      cloud_name: vuexStore.state.config.cloudinaryInfo.cloud_name,
      api_key: vuexStore.state.config.cloudinaryInfo.api_key,
    });
  }

  async loadSurveys() {
    try {
      const availableSurveys = await httpGet('surveys/schemas/available');
      vuexStore.commit('SurveyStore/setAvailableSurveys', availableSurveys);
    } catch (ex) {
      console.error(ex);
      vuexStore.commit('SurveyStore/setSurveyErrorMessage', ex.error.message);
    }
  }

  async setupApp() {
    if (vuexStore.state.config.obj_texts.ReactionTypesInternal.includes('survey')
      || vuexStore.state.config.obj_texts.ReactionTypesExternal.includes('survey')) {
      await this.loadSurveys();
    }
    vuexStore.commit('loadDisplayModes');
    await this.setRoleUpdateUI(vuexStore.state.user.userRole, 'get');
    createEventHandlers(window.socketClient);
    registerEventHandlersToVuex();
    await window.vuexStore.dispatch('ReportStore/loadReportTypes');

    // cookie crap not working let currentPage = this.getCurrentPageFromPreviousSession();
    let currentPage = window.vuexStore.state.user.appStartPage;
    if (vuexStore.state.user.userRole === 'external') {
      currentPage = Views.VisitPlansApp;
    }
    vuexStore.commit('setCurrentPage', currentPage);
  }

  async setupAdmin() {
    if (vuexStore.state.user.backendAccess === true) {
      $('.login ul li').hide();
      $('.login ul li.loader').fadeIn();

      vuexStore.commit('setUserProperties', { userRole: 'internal' });
      if (vuexStore.state.user.backendStartPage === 'Feed') {
        vuexStore.commit('setCurrentPage', Views.AdminFeed);
        vuexStore.commit('setFullScreen', true);
      } else {
        vuexStore.commit('setCurrentPage', Views.Dashboard);
      }
    } else {
      const cookieValue = encodeURI(vuexStore.state.accessToken);
      Cookies.set(CookieNames.accessToken, cookieValue, { expires: -1 });
      vuexStore.commit('setAccessToken', null);

      vuexStore.commit('markAllNotificationsRead');
      $('.login ul li.err_msg').fadeIn();
    }

    this.initializeCalendars();
    createEventHandlers(window.socketClient);
    registerEventHandlersToVuex();
    await window.vuexStore.dispatch('ReportStore/loadReportTypes');
  }

  async getProperties() {
    const properties = await httpGet('properties/simple');

    const imageWidth = window.devicePixelRatio === 1 ? 300 : 400;

    // Extract some properties that should go directly
    // on the config object instead of into obj_texts
    const { imageBaseUrl } = properties;
    const thumbBaseUrl = `${imageBaseUrl}c_fill,w_${imageWidth},q_60/`;
    const { cloudinaryInfo } = properties;
    delete properties.imageBaseUrl;
    delete properties.cloudinaryInfo;

    window.vuexStore.commit('setProperties', properties);
    window.vuexStore.commit('setConfigProperties', { cloudinaryInfo, imageBaseUrl, thumbBaseUrl });
    window.vuexStore.commit('markLoaded', { cloudinaryInfo: true });

    this.setFeatures(properties);
    this.getAvailableReactions();
  }

  createSocketClient(mode) {
    if (!window.socketClient) {
      window.socketClient = new SocketClient();
    }
    window.socketClient.createSocket(mode);
  }

  getAvailableReactions() {
    const union = vuexStore.state.config.obj_texts.ReactionTypesInternal
      .concat(vuexStore.state.config.obj_texts.ReactionTypesExternal);

    // Remove duplicates
    const availableReactions = {};
    union.forEach((type) => {
      availableReactions[type] = type;
    });

    vuexStore.state.config.obj_texts.availableReactions = Object.keys(availableReactions);
  }

  initializeCalendars() {
    $('.date-picker:text').each(function initCals() {
      // noinspection JSUnresolvedFunction
      $(this).datePicker();
    });
  }

  /**
   * Sets up the system according to the user role.
   * @param {string} cRole
   * @param {string} status 'get' or 'set': Get means set up according to existing. Set means set up with new value.
   * @returns {Promise<void>}
   */
  async setRoleUpdateUI(cRole, status) {
    // TODO: NEEDS CLEANUP
    /**
     * notification count: Skal måske i topbar eller i vuex, hvor notification count indlæses, når
     * appen indlæses, og indlæses igen, hvis userRole skifter.
     *
     * Når man skifter user role, skal man også se, om den side, man er på, er tilgængelig for den
     * nye rolle. Hvis ikke, skal man skifte til default-siden.
     *
     * Den nuværende rolle skal huskes i cookie
     *
     * Og så skal userRole skrives til vuex (setUserProperties)
     */
    vuexStore.commit('setUserProperties', { userRole: cRole });

    if (status === 'set') {
      Cookies.set(CookieNames.userRole, vuexStore.state.user.userRole, { expires: 4 });
    }

    let didChangeRole = false;

    if (status === 'get') {
      const cUserRole = Cookies.get(CookieNames.userRole);

      if (cUserRole !== vuexStore.state.user.userRole && cUserRole !== undefined) {
        vuexStore.commit('setUserProperties', { userRole: cUserRole });
        await this.setRoleUpdateUI(cUserRole, 'set');
        didChangeRole = true;
      } else {
        didChangeRole = false;
      }
    }

    if (!didChangeRole) {
      // this.getHistory();

      const data = await httpGet(`users/me/notificationcount?userrole=${window.vuexStore.state.user.userRole}`);
      const { unread } = data;
      vuexStore.commit('setUnreadNotificationCount', unread);
      if (cRole === 'internal') {
        vuexStore.dispatch('VisitPlanStore/clearVisitPlans');
        vuexStore.commit('setCurrentPage', window.vuexStore.state.user.appStartPage);
      } else {
        vuexStore.dispatch('VisitPlanStore/loadVisitPlansForUser');
        vuexStore.commit('setCurrentPage', Views.VisitPlansApp);
      }
      vuexStore.commit('CachingStore/clearCustomerCache');
    }
  }
}
