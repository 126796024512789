import lang from '../classes/lang';

/*eslint-disable*/
import moment from 'moment';
moment.locale('da');

(function ($) {
	/* "YYYY-MM[-DD]" => Date */
	function strToDate(str, format) {
		try {
			if (str !== "") {
				return moment(str, format).toDate();
			} else {
				return new Date();
			}
		} catch (err) {
		} // just throw any illegal format
	}

	/* Date => "YYYY-MM-DD" */
	function dateToStr(d, format) {
		return moment(d).format(format);
	}

	$.fn.calendar = function (options) {
		const _this = this;
		_this.data("dateformat", options.dateformat);
		_this.data("headerformat", options.headerformat);

		const opts = $.extend({}, $.fn.calendar.defaults, options);
		const week = [
			lang("shortMonday"),
			lang("shortTuesday"),
			lang("shortWednesday"),
			lang("shortThursday"),
			lang("shortFriday"),
			lang("shortSaturday"),
			lang("shortSunday")
		];
		const tHead = week.map(function (day) {
			return `<th>${day}</th>`;
		}).join("");

		_this.init = function () {
			const tpl = `
				<table class="cal">
					<caption>
						<span class="prev"><a href="javascript:void(0);">&larr;</a></span>
						<span class="prev-year"><a href="javascript:void(0);">&lArr;</a></span>
						<span class="next"><a href="javascript:void(0);">&rarr;</a></span>
						<span class="next-year"><a href="javascript:void(0);">&rArr;</a></span>
						<span class="month"><span>
					</caption>
					<thead>
						<tr>${tHead}</tr>
					</thead>
					<tbody></tbody>
				</table>
			`;
			const html = $(tpl);
			_this.append(html);
		};

		function daysInMonth(d) {
			const newDate = new Date(d);
			newDate.setMonth(newDate.getMonth() + 1);
			newDate.setDate(0);
			return newDate.getDate();
		}

		_this.update = function (date) {
			const mDate = new Date(date);
			mDate.setDate(1);
			/* start of the month */
			let day = mDate.getDay() - 1;
			if (day === -1) { day = 6; }
			/* value 0~6: 0 -- Sunday, 6 -- Saturday */
			// NOTE: Danish calendar: Subtracted 1 from the day to start the week on monday instead of sunday.
			mDate.setDate(mDate.getDate() - day);
			/* now mDate is the start day of the table */

			function dateToTag(d) {
				const tag = $('<td><a href="javascript:void(0);"></a></td>');
				const a = tag.find('a');
				a.text(d.getDate());
				a.data('date', dateToStr(d, _this.data("dateformat")));
				if (date.getMonth() !== d.getMonth()) { // the boundary month
					tag.addClass('off');
				} else if (_this.data('date') === a.data('date')) { // the select day
					tag.addClass('active');
					_this.data('date', dateToStr(d, _this.data("dateformat")));
				}
				return tag;
			}

			const tBody = _this.find('tbody');
			tBody.empty();
			/* clear previous first */
			const cols = Math.ceil((day + daysInMonth(date)) / 7);
			for (let i = 0; i < cols; i++) {
				const tr = $('<tr></tr>');
				for (let j = 0; j < 7; j++, mDate.setDate(mDate.getDate() + 1)) {
					tr.append(dateToTag(mDate));
				}
				tBody.append(tr);
			}

			const footer = $(`<tr><td colspan="7" class="delete_button">
<a href="javascript:void(0);">${lang('delete')}</a></td></tr>`);
			tBody.append(footer);

			/* set month head */
			const monthStr = moment(date).format(_this.data("headerformat"));
			_this.find('.month').text(monthStr)
		};

		_this.getCurrentDate = function () {
			return _this.data('date');
		};

		_this.init();
		/* in date picker mode, and input date is empty,
		 * should not update 'data-date' field (no selected).
		 */
		const initDate = opts.date ? opts.date : new Date();
		if (opts.date || !opts.picker) {
			_this.data('date', dateToStr(initDate, _this.data("dateformat")));
		}
		_this.update(initDate);

		/* event binding */
		_this.delegate('tbody td', 'click', function () {
			const $this = $(this);
			if ($this.hasClass('delete_button')) {
			  _this.data('date', null);
			  _this.val(null);
      } else {
        _this.find('.active').removeClass('active');
        $this.addClass('active');
        _this.data('date', $this.find('a').data('date'));
        /* if the 'off' tag become selected, switch to that month */
        if ($this.hasClass('off')) {
          _this.update(strToDate(_this.data('date'), _this.data("dateformat")));
        }
      }
			if (opts.picker) {  /* in picker mode, when date selected, panel hide */
				_this.hide();
			}
		});

		function updateTable(monthOffset) {
			//var date = strToDate(_this.find('.month').text());
			const date = moment(_this.find('.month').text(), _this.data("headerformat")).add(monthOffset, 'months').toDate();

//			date.setMonth(date.getMonth() + monthOffset);
			_this.update(date);
		}

		_this.find('.next').click(function () {
			updateTable(1);

		});

    _this.find('.next-year').click(function () {
      updateTable(12);

    });

		_this.find('.prev').click(function () {
			updateTable(-1);
		});

		_this.find('.prev-year').click(function () {
			updateTable(-12);
		});

		return this;
	};

	$.fn.calendar.defaults = {
		date: new Date(),
		picker: false,
	};

	$.fn.datePicker = function () {
		const _this = this;
		//var dateformat = _this.attr("data");
		const dateformat = _this.data("dateformat");
		const headerformat = _this.data("headerformat");
		_this.data("dateformat", dateformat || "DD-MM-YYYY");
		_this.data("headerformat", headerformat || "DD-MM-YYYY");

		const date = strToDate(_this.val(), _this.data("dateformat"));

		const picker = $('<div></div>')
			.addClass('picker-container')
			.hide()
			.calendar({'date': date, 'picker': true, dateformat: dateformat, headerformat: headerformat});

		_this.after(picker);

		/* event binding */
		// click outside area, make calendar disappear
		$('body').click(function () {
			picker.hide();
		});

		// click input should make calendar appear
		_this.click(function () {
			picker.show();
			return false; // stop sending event to document
		});

		// click on calender, update input
		picker.click(function () {
			_this.val(picker.getCurrentDate());
			return false;
		});

		return this;
	};

	// $(window).load(function () {
	// 	$('.date-picker:text').each(function () {
	// 		$(this).datePicker();
	// 	});
	// });
}($));
