import eventNames from '../enums/event-names';

/**
 * This sets up listeners for all events coming from socket.io
 * and publishes them on the event bus. In this way, all
 * components can listen to the event bus for server events.
 * @param socketClient
 */
export function createEventHandlers(socketClient) {
  Object.values(eventNames).forEach((eventName) => {
    socketClient.on(eventName, (data) => {
      console.debug('Socket event: ', eventName, data);
      window.EventBus.emit(eventName, data);
    });
  });
}
