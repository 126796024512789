import { defineAsyncComponent } from 'vue';
import {
  createRouter,
  createMemoryHistory,
} from 'vue-router';

const Feed = defineAsyncComponent(() => import('../components/Pages/Feed'));
const UserProfile = defineAsyncComponent(() => import('../components/UserProfile/UserProfileFrame'));
const Dashboard = defineAsyncComponent(() => import('../components/Dashboard/Dashboard'));
const DataTable = defineAsyncComponent(() => import('../components/DataList/DataTable'));
const AdminFeedPage = defineAsyncComponent(() => import('../components/AdminFeed/AdminFeedPage'));
const Gallery = defineAsyncComponent(() => import('../components/Gallery/Gallery'));
const SystemSettings = defineAsyncComponent(() => import('../components/SystemSettingsPage'));
const CustomerList = defineAsyncComponent(() => import('@/components/Customer/CustomerList'));
const Blank = defineAsyncComponent(() => import('@/components/Blank'));
const VisitPlansContainer = defineAsyncComponent(() => import('@/components/VisitPlans/VisitPlanContainer'));

export default function setupVueRouter() {
  // TODO: TEST VUE3
  // Vue.use(VueRouter);
  return createRouter({
    history: createMemoryHistory(),
    routes: [
      { path: '/feed/:type', component: Feed },
      { path: '/profile', component: UserProfile },
      { path: '/admin/Dashboard', component: Dashboard },
      { path: '/admin/datalist/:dataType/:createPhrase', component: DataTable, props: true },
      { path: '/admin/AdminFeed', component: AdminFeedPage },
      { path: '/admin/Gallery', component: Gallery },
      { path: '/admin/SystemSettings', component: SystemSettings },
      // This is only in the app. In admin, customers go via the datalist route.
      { path: '/customers', component: CustomerList },
      { path: '/visit-plans-app', component: VisitPlansContainer },
      { path: '/blank', component: Blank },
      { path: '/', component: Blank },
    ],
  });
}
