import Vuex from 'vuex';
import { isEmpty, isObject } from 'lodash';
import Cookies from 'js-cookie';

import ReportStore from '@/vuex/modules/ReportStore';
import FeedStore from '@/vuex/modules/FeedStore';
import WindowStore from '@/vuex/modules/WindowStore';
import CachingStore from '@/vuex/modules/CachingStore';
import DownloadStore from '@/vuex/modules/DownloadStore';
import DataEditorStore from '@/vuex/modules/DataEditorStore';
import GoogleMapsLoaderStore from '@/vuex/modules/GoogleMapsLoaderStore';
import LocationStore from '@/vuex/modules/LocationStore';
import OfflineStore from '@/vuex/modules/OfflineStore';
import SurveyStore from '@/vuex/modules/SurveyStore';
import VisitPlanStore from '@/vuex/modules/VisitPlanStore';

import Views from '@/enums/views';
import CookieNames from '@/enums/cookie-names';
import DisplayModes from '@/enums/display-modes';
import FeatureToggleStore from '@/vuex/modules/FeatureToggleStore';
import checkForUnreadAnnouncements from '../classes/AnnouncementHelper';

export default function setupVuex(config) {
  return new Vuex.Store({
    modules: {
      CachingStore,
      DataEditorStore,
      DownloadStore,
      FeatureToggleStore,
      FeedStore,
      GoogleMapsLoaderStore,
      LocationStore,
      OfflineStore,
      ReportStore,
      SurveyStore,
      VisitPlanStore,
      WindowStore,
    },
    state: {
      accessToken: '',
      user: null,
      config,
      currentPage: null,
      currentReactionType: null,
      isMenuOpen: false,
      dashboardOptions: {
        days: 31,
        selectedChannels: [],
        userRoles: ['internal', 'external'],
      },
      dashboardVisible: false,
      displayModes: {
        // The props in displayModes must conform to the names in views.js, and the values to the
        // names in DisplayModes
        MyPosts: DisplayModes.LargeCards,
        MyDistrict: DisplayModes.SmallCards,
        AllPosts: DisplayModes.LargeCards,
        Bookmarks: DisplayModes.SmallCards,
        Gallery: DisplayModes.LargeCards,
        OwnVisitPlanVisits: DisplayModes.LargeCards,
        AllVisitPlanVisits: DisplayModes.LargeCards,
      },
      // The items being shown in the gallery according to the current filter.
      galleryItems: [],
      popups: [],
      // Filter for the feed in the app
      filter: null,
      // An image that is being viewed in full screen, in a zoomable view.
      zoomImage: null,
      // Flag that indicates that the backend is shown fullscreen, i.e. no left menu
      isFullScreen: false,
      notifications: [],
      unreadNotificationCount: 0,
      isLoaded: {
        publicProperties: false,
        properties: false,
        cloudinaryInfo: false,
        userData: false,
        translation: false,
        loadedLanguage: '',
      },
      topBarSuffix: '',
      // TODO: MOVE INTO THE NEW FEED MODULE
      // Contains an array of the ids currently loaded into the feed.
      feedItemIds: [],
    },
    getters: {
      language: (state) => {
        if (state.user === null) return state.config.DefaultLanguage;
        if (state.user.language === undefined || state.user.language === null) {
          return state.config.DefaultLanguage;
        }
        return state.user.language;
      },
      // isLoggedIn: If we have user data and an access token. Token validity is not checked;
      // the server endpoints will not work with an improper one anyway.
      isLoggedIn: (state) => !isEmpty(state.user)
        && !isEmpty(state.accessToken)
        && state.isLoaded.properties === true
        && state.isLoaded.cloudinaryInfo === true,
    },
    mutations: {
      reset(state) {
        state.accessToken = null;
        state.user = null;
        state.currentReactionType = null;
        state.isMenuOpen = false;
        state.uploadMultiple = false;
        state.dashboardOptions = {
          days: 31,
          selectedChannels: [],
          userRoles: ['internal', 'external'],
        };
        state.dashboardVisible = false;
        state.galleryItems = [];
        state.popups = [];
        state.filter = null;
        state.zoomImage = null;
        state.isFullScreen = false;
        state.notifications = [];
        state.unreadNotificationCount = 0;
        state.isLoaded = {
          publicProperties: false,
          properties: false,
          cloudinaryInfo: false,
          userData: false,
          translation: false,
          loadedLanguage: '',
        };
        state.feedItemIds = [];
      },
      setTranslation(state, payload) {
        state.config.translation = payload.translation;
        state.isLoaded = { ...state.isLoaded, translation: true, loadedLanguage: payload.language };
      },
      setProperties(state, properties) {
        state.config.obj_texts = properties;
        state.isLoaded = { ...state.isLoaded, properties: true };
      },
      setFeatures(state, features) {
        state.config.features = features;
      },
      setConfigProperties(state, data) {
        state.config = { ...state.config, ...data };
      },
      setUserData(state, userData) {
        state.user = userData;
        state.isLoaded = { ...state.isLoaded, userData: true };
      },
      setUserProperties(state, data) {
        state.user = { ...state.user, ...data };
      },
      setAccessToken(state, token) {
        state.accessToken = token;
      },
      setCurrentPage(state, currentPage) {
        if (Views[currentPage] === undefined) {
          console.warn(`Unknown page '${currentPage}' committed to store.`);
          return;
        }
        state.currentPage = currentPage;
      },
      setMenuStateClosed(state) {
        state.isMenuOpen = false;
      },
      setMenuStateOpen(state) {
        state.isMenuOpen = true;
      },
      setDashboardOptions(state, options) {
        state.dashboardOptions = { ...state.dashboardOptions, ...options };
      },
      setDashboardVisible(state, isVisible) {
        state.dashboardVisible = isVisible;
      },
      setDisplayMode(state, payload) {
        state.displayModes = { ...state.displayModes, ...payload };
        Cookies.set(CookieNames.displayMode, JSON.stringify(state.displayModes), { expires: 50 });
      },
      setGalleryItems(state, items) {
        state.galleryItems = items;
      },
      removeItemFromGallery(state, itemId) {
        state.galleryItems = this.state.galleryItems.filter((item) => item.id !== itemId);
      },
      loadDisplayModes(state) {
        const displayModesString = Cookies.get(CookieNames.displayMode);
        if (displayModesString !== null && displayModesString !== undefined && displayModesString !== '') {
          const displayModes = JSON.parse(displayModesString);
          state.displayModes = { ...state.displayModes, ...displayModes };
        }
      },
      /**
       * @param state
       * @param { PopupOptions } popupOptions This object
       * will be passed into the content property of the Popup component.
       */
      pushPopup(state, popupOptions) {
        if (isEmpty(popupOptions)
          || !isObject(popupOptions.component
            || isEmpty(popupOptions.title))
        ) {
          console.warn('Popup inserted without component or title properties.');
        }
        if (state.popups.length > 0) {
          const previousPopup = state.popups[state.popups.length - 1];
          if (previousPopup.hideWhenShowingNext) {
            console.log('Removing previous popup.');
            state.popups.pop();
          }
        }
        state.popups.push(popupOptions);
      },
      popPopup(state) {
        state.popups.pop();
      },
      setFilter(state, filter) {
        state.filter = filter;
      },
      setZoomImage(state, image) {
        state.zoomImage = image;
      },
      setFullScreen(state, isFullScreen) {
        state.isFullScreen = isFullScreen;
      },
      setNotifications(state, notificationsList) {
        state.notifications = notificationsList;
      },
      pushNotification(state, notification) {
        // New notification should be added to the beginning of the array.
        state.notifications.unshift(notification);
      },
      setUnreadNotificationCount(state, count) {
        state.unreadNotificationCount = count;
      },
      increaseUnreadNotifications(state) {
        state.unreadNotificationCount += 1;
      },
      markAllNotificationsRead(state) {
        state.unreadNotificationCount = 0;
      },
      markLoaded(state, options) {
        state.isLoaded = { ...state.isLoaded, ...options };
      },
      setTopBarSuffix(state, text) {
        state.topBarSuffix = text;
      },
      /**
       *
       * @param state
       * @param itemIds An array of ids
       */
      setFeedItemIds(state, itemIds) {
        state.feedItemIds = itemIds;
      },
      /**
       * While creating/editing a visit, the reaction type currently selected is represented here.
       * This is used by sub-pages (selectedList), which might show different data depending on
       * the type chosen (currently: If survey, then not all chains are shown, only the ones
       * relating to the surveyType of the logged-in user.)
       * @param state
       * @param {string} reactionType
       */
      setCurrentReactionType(state, reactionType) {
        state.currentReactionType = reactionType;
      },
    },
    actions: {
      async openMenu(context) {
        context.commit('setMenuStateOpen');

        // Whenever we open the menu, we check for unread announcements from the server.
        await checkForUnreadAnnouncements();
      },
      closeMenu(context) {
        context.commit('setMenuStateClosed');
      },
      // Logs out of the application. Resets UI state and cache
      resetAll(context) {
        context.commit('CachingStore/reset');
        context.commit('DownloadStore/reset');
        context.commit('DataEditorStore/reset');
        context.commit('FeedStore/reset');
        context.commit('ReportStore/reset');
        context.commit('SurveyStore/reset');
        context.commit('reset');
      },
    },
  });
}
