/**
 * These are the data types we store in the cache. They must be the same as the
 * actual property field names in the cache object.
 * @type {{}}
 */
export default {
  campaigns: 'campaigns',
  chains: 'chains',
  channels: 'channels',
  customers: 'customers',
  products: 'products',
};
