/* eslint-disable */
export default {
  methods: {
    getEnvironment() {
      // noinspection NodeModulesDependencies, ES6ModulesDependencies
      return `Screen Width : ${window.screen.width}`
        + `<br> Screen Height : ${window.screen.height}`
        + `<br>${(platform.name !== null) ? ` Browser Name : ${platform.name}` : ''
        }<br>${(platform.version !== null) ? ` Browser Version : ${platform.version}` : ''
        }<br>${(platform.os !== null) ? ` OS : ${platform.os}` : ''
        }<br>${(platform.os.family !== null) ? ` OS family : ${platform.os.family}` : ''
        }<br>${(platform.os.version !== null) ? ` OS Version : ${platform.os.version}` : ''
        }<br>${(platform.os.architecture !== null) ? ` OS Architecture : ${platform.os.architecture}` : ''
        }<br>${(platform.manufacturer !== null) ? ` Manufacturer : ${platform.manufacturer}` : ''
        }<br>${(platform.product !== null) ? ` Product : ${platform.product}` : ''
        }<br>${(this.$store.state.LocationStore.isGeolocationSupported !== null) ? ` isGeolocationSupported : ${this.$store.state.LocationStore.isGeolocationSupported}` : ''
        }<br>${(this.$store.state.LocationStore.locationError !== null) ? ` LocationError : ${this.$store.state.LocationStore.locationError.message}` : 'LocationError : N/A'
        }` + `<br>` + ` UserAgent : ${navigator.userAgent}`;
    },
  },
};
