/**
 * These are the places where it is possible to order a report
 * The Report_Types.applicationOptions should only contain values
 * that are present in this enum.
 */
export default {
  feed: 'feed', // Application feed
  itemDetails: 'item', // Item details
  gallery: 'gallery', // Admin gallery
};
