export default {
  methods: {
    /**
     * Determines if an object is either null, undefined or an empty string.
     * @param obj
     * @returns {boolean}
     */
    isEmpty(obj) {
      return obj === undefined || obj === null || obj === '';
    },
  },
};
