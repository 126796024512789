import Cookies from 'js-cookie';
import { httpGet } from './httpHelper';
import CookieNames from '../enums/cookie-names';

export default class Login {
  constructor() {
    console.log('Login class constructed.');
  }

  static async checkUserLogin() {
    const accessToken = decodeURI(Cookies.get(CookieNames.accessToken));

    if (accessToken !== 'undefined' && accessToken !== 'null' && accessToken !== '') {
      window.vuexStore.commit('setAccessToken', accessToken);
      const user = await this.LoadUser();
      if (user.appAccess) {
        window.vuexStore.commit('setUserData', user);
        return true;
      }
    }

    return false;
  }

  static async LoadUser() {
    const user = await httpGet('users/me');
    user.initialUserRole = user.userRole;
    if (user.userRole === 'both') user.userRole = 'internal';
    user.isFullObject = true;
    return user;
  }
}
