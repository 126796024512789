export default {
  namespaced: true,
  state: {
    isGoogleMapsLoaded: false,
  },
  mutations: {
    markGoogleMapsLoaded(state) {
      state.isGoogleMapsLoaded = true;
    },
  },
};
