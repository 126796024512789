import { httpGet } from '@/classes/httpHelper';
import DataTypes from '../../enums/data-types';

/**
 * This module holds a data cache meant to hold large datasets in order to prevent
 * having to load them from the server too often.
 */
export default {
  namespaced: true,
  state: {
    campaigns: null,
    chains: null,
    channels: null,
    customers: null,
    products: null,
    districts: null,

    // Geo position we were at when customers list was cached. Is used because it includes some
    // distance calculations.
    cachingPosition: null,
    // If we use the "No Customer" feature, this holds the customer object used as
    // "No Customer".
    noCustomer: null,

    // Choices are very small lookup-datasets.
    choices: {},
  },
  mutations: {
    // Deletes the entire cache
    reset(state) {
      for (const item in state) {
        if (Object.prototype.hasOwnProperty.call(state, item)) {
          state.item = null;
        }
      }
    },
    setCustomerCache(state, customers) {
      state.customers = customers;
    },
    setCustomerCacheAndPosition(state, payload) {
      const { customers, cachingPosition } = payload;
      if (!payload.customers) {
        console.error('Customer data missing in call to setCustomerCache.');
        return;
      }

      state.customers = customers;
      state.cachingPosition = cachingPosition;
    },
    setNoCustomer(state, noCustomerCustomer) {
      state.noCustomer = noCustomerCustomer;
    },
    clearCustomerCache(state) {
      state.customers = null;
      state.cachingPosition = null;
      state.noCustomer = null;
    },
    setCampaignCache(state, campaigns) {
      state.campaigns = campaigns;
    },
    setChainCache(state, chains) {
      state.chains = chains;
    },
    setChannelCache(state, channels) {
      console.log('setChannelCache');
      state.channels = channels;
    },
    setProductCache(state, products) {
      state.products = products;
    },
    setDistrictCache(state, districts) {
      state.districts = districts;
    },
    /**
     * Updates the cache by adding, updating or removing an element.
     * If the cache does not contain the specified type of data, nothing is done.
     * @param state
     * @param payload
     * @param { string } payload.dataType The type of data to update
     * @param { string } payload.action Can be 'create', 'update', 'delete'
     * @param { object } payload.element The element to update
     */
    updateCache(state, payload) {
      const { dataType, action, element } = payload;
      console.log('updateCache');
      if (!state[dataType]) return;
      const cache = state[dataType];
      let index;
      switch (action) {
        case 'create':
          cache.unshift(element);
          break;
        case 'update':
          if (!element.id) {
            console.warning('cannot update the cache with an element without an id.');
            return;
          }
          index = cache.findIndex((e) => e.id === element.id);
          if (index > -1) {
            // TODO: TEST VUE3
            cache[index] = element;
            // Vue.set(cache, index, element);
          }
          break;
        case 'delete':
          { // Necessary in order to make the const in the next line.
            const id = element;
            if (!id) {
              console.warning('cannot update the cache with an element without an id.');
              return;
            }
            index = cache.findIndex((e) => e.id === id);
            if (index > -1) {
              cache.splice(index, 1);
            }
          }
          break;
        default:
          console.warn(`Unknown cache command ${action} encountered.`);
      }
    },
    updateCustomerImages(state, customerImages) {
      console.log('updateCustomerImages', customerImages);
    },
    addManualCustomerToCache(state, customer) {
      if (Array.isArray(state.customers)) {
        state.customers.push(customer);
      }
    },
    setChoicesList(state, { listName, data }) {
      state.choices[listName] = data;
    },
  },

  getters: {
    getChoicesList: (state) => (listName) => state.choices[listName] || [],
  },

  actions: {
    /** Ensures that customers have been loaded into cache. If they are not, this loads them.
     * @param {object} context The vuex context provided for actions.
     * @param {object} payload Options
     * @param {{latitude: number, longitude: number}} payload.position property containing
     * lat and long for the user's current position. If this is provided, the loaded customers
     * will have a distance property enabling sorting based on current position.
     * */
    async requireCustomers(context, payload) {
      const { commit, rootState } = context;
      const { position } = payload || {};
      const userRole = rootState.user.userRole;
      let url;
      if (position) {
        if (userRole === 'internal') {
          url = `customers/bydistance?lat=${this.position.latitude}&long=${this.position.longitude}&channelonly=true`;
        } else {
          // TODO: This might never be necessary, if this cache is only used in admin.
          // We don't expect any external users to access admin, do we?
          url = `customers/byvisitplansforuser/${rootState.user.id}`;
        }
      } else {
        if (context.state.customers != null) {
          console.log('Customers are already in cache.');
          return;
        }
        url = `customers?userrole=${userRole}`;
      }
      const customers = await httpGet(url);
      commit('setCustomerCache', customers);
    },
    /**
     *
     * @param context
     * @param {object} payload
     * @param {string} payload.dataType Specifies which cache to ensure has loaded
     * @returns {Promise<void>}
     */
    async requireDataType(context, payload) {
      const { commit } = context;
      const { dataType } = payload;
      if (context.state[dataType] != null) {
        console.log(`${dataType} is already in cache.`);
        return;
      } // list is already in cache
      console.log(`Retrieving data type: ${dataType}`);
      const data = await httpGet(dataType);
      switch (dataType) {
        case DataTypes.campaigns: commit('setCampaignCache', data); break;
        case DataTypes.chains: commit('setChainCache', data); break;
        case DataTypes.channels: commit('setChannelCache', data); break;
        case DataTypes.districts: commit('setDistrictCache', data); break;
        case DataTypes.products: commit('setProductCache', data); break;
        default:
          console.error(`Unknown data type ${dataType} specified in caching module.`);
          break;
      }
    },
    /**
     * This indicates that a component requires a specific Choices list.
     * It works like a cache, i.e. if the list is already loaded, nothing will
     * happen. But if it has not been loaded, then a query will be sent off. */
    async requireChoicesList(context, listName) {
      const { commit, state } = context;
      if (state.choices[listName]) {
        // Data is already loaded.
        return;
      }

      console.debug(`Loading choices list ${listName}`);
      const data = await httpGet(`choices/${listName}`);
      commit('setChoicesList', { listName, data });
    },
  },
};
