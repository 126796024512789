const DisplayModes = {
  // Cards that are full width on a mobile device in portrait mode
  LargeCards: 'largecards',
  // Cards that fill two columns on a mobile device in portrait mode
  SmallCards: 'smallcards',
  // Cards without text, instagram-style
  Insta: 'insta',

  /** Utility function to cycle modes */
  nextMode(currentMode) {
    switch (currentMode) {
      case this.LargeCards: return this.SmallCards;
      case this.SmallCards: return this.Insta;
      default: return this.LargeCards;
    }
  },
};
export default DisplayModes;
