<template>
  <div v-if="!isLoggedIn" class="not_logged_in">
    <ul id="grid">
      <li id="contentMain">
        <div class="login">
          <transition name="fade" mode="out-in">
            <reset-password v-if="showResetPassword" @close="closeResetPassword()"/>
            <form v-else @submit.prevent="formLogin">
              <ul>
                <li class="logo">
                  <span class="ThemeLoginScreenLogoBox">
                    <img src="api/v1/logo.png" alt="logo"/>
                  </span>
                </li>
                <li v-if="loginError" class="err_msg lsc-rounded-10">
                  <icon name="fa-exclamation-circle"/>{{ x('loginErrorMessage') }}
                </li>
                <li>
                  <input title="username" type="text" class="lsc-input lsc-rounded-10"
                         autocomplete="username"
                         :placeholder="x('userNameDots')" name="login_email" v-model="username">
                </li>
                <li>
                  <input title="password" type="password" class="lsc-input lsc-rounded-10"
                         autocomplete="current-password"
                         :placeholder="x('passwordDots')" name="login_password" v-model="password">
                </li>

                <li>
                  <button type="button" id="login_submit" class="lsc-button lsc-rounded-10 green"
                          @click="buttonLogin()">
                    <icon name="fa-sign-in"/>{{ x(loginButtonTitle) }}
                  </button>
                </li>
                <br>
                <li>
                  <a href="javascript:void(0)"
                     class="txtWhite padT20 displayBlock underlineN fontItalic"
                     @click="forgotPassword()">
                    {{ x('loginForgotten') }}
                  </a>
                </li>
                <li class="marT20">
                  <img :src="'/static/assets/gfx/'+config.publicProperties.PosmoLogoFrontPage"
                       alt="Posmo Logo" width="195" height="81"/><br>
                  <span class="ThemeLoginScreenText">
                    Version: <b class="cVersion">{{ version }}</b>
                  </span><br>
                </li>
              </ul>
            </form>
          </transition>
          <div class="hotfix-air"></div>
        </div>
      </li>
    </ul>
  </div>
  <component v-else :is="config.mode" ref="main"/>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import { mapState, mapGetters, mapMutations } from 'vuex';
  import Cookies from 'js-cookie';
  import { httpPost } from '@/classes/httpHelper';
  import CookieNames from '@/enums/cookie-names';
  import translate from '../Mixins/Translate';
  import getEnvironment from '../Mixins/GetEnvironment';

  export default {
    name: 'login',
    mixins: [translate, getEnvironment],
    components: {
      app: defineAsyncComponent(() => import('../App')),
      admin: defineAsyncComponent(() => import('../Admin')),
      'reset-password': defineAsyncComponent(() => import('./ResetPassword')),
    },
    computed: {
      loginButtonTitle() {
        return this.config.mode === 'app' ? 'logIn' : 'logInBackOffice';
      },
      version() {
        return this.config.mode === 'app'
          ? this.config.publicProperties.MobileWebAppVer
          : this.config.publicProperties.BackOfficeVer;
      },
      ...mapState(['config', 'isLoaded']),
      ...mapGetters(['isLoggedIn', 'language']),
    },
    data() {
      return {
        username: '',
        password: '',
        loginError: false,
        showResetPassword: false,
        isLoggingIn: false,
      };
    },
    methods: {
      async formLogin() {
        // Prevents double events from happening from both button and form submissions.
        if (this.isLoggingIn) return false;
        this.isLoggingIn = true;
        await this.login();
        this.isLoggingIn = false;
        return false;
      },

      async buttonLogin() {
        // Prevents double events from happening from both button and form submissions.
        if (this.isLoggingIn) return false;
        this.isLoggingIn = true;
        await this.login();
        this.isLoggingIn = false;
        return false;
      },

      async login() {
        this.loginError = false;
        const credentials = {
          username: this.username,
          password: this.password,
          environment: this.getEnvironment(),
          loginSource: this.config.mode,
        };

        let result;
        try {
          result = await httpPost('login', JSON.stringify(credentials));
        } catch (e) {
          this.loginError = true;
          return false;
        }

        const isBackend = this.config.mode === 'admin';

        if (result.error
          || (!isBackend && result.appAccess === false)
          || (isBackend && result.backendAccess === false)
        ) {
          this.loginError = true;
          return false;
        }

        console.info('Login success.');

        const cookieValue = encodeURI(result.token);
        let ttl = this.config.publicProperties.AccessTokenTTL;
        if (ttl) {
          ttl = parseInt(ttl, 10);
        } else {
          ttl = 4;
        }
        Cookies.set(CookieNames.accessToken, cookieValue, { expires: ttl });

        this.setAccessToken(result.token);
        result.initialUserRole = result.userRole;
        if (result.userRole === 'both') result.userRole = 'internal';
        this.setUserData(result);
        if (this.isLoaded.loadedLanguage !== result.language) {
          await window.bootstrap.getTranslation(result.language);
        }
        await window.bootstrap.trueUserLogin();
        return false;
      },
      forgotPassword() {
        this.showResetPassword = true;
      },
      closeResetPassword() {
        this.showResetPassword = false;
      },
      ...mapMutations(['setAccessToken', 'setUserData']),
    },
  };
</script>

<style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .2s
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
  {
    opacity: 0
  }
</style>
