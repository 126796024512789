/* eslint-disable max-len */
import FeatureNames from '@/enums/feature-names';

/**
 * This store handles information about which features are enabled.
 */
export default {
  namespaced: true,
  getters: {
    areSurveysEnabled: (state, getters, rootState) => rootState
      .config.obj_texts.ReactionTypesInternal.includes('survey')
      || rootState.config.obj_texts.ReactionTypesExternal.includes('survey'),
    areVisitPlansEnabled: (state, getters, rootState) => rootState.config.features[FeatureNames.visitPlans],
    areBookmarksEnabled: (state, getters, rootState) => rootState.config.features[FeatureNames.bookmarks],
    areProductsVisibleInMenu: (state, getters, rootState) => rootState.config.features[FeatureNames.menuViewProducts],
    areCampaignsVisibleInMenu: (state, getters, rootState) => rootState.config.features[FeatureNames.menuViewCampaigns],
    isUserRoleFilterEnabled: (state, getters, rootState) => rootState.config.features[FeatureNames.filterViewUserRoles],
    isReactionsFilterEnabled: (state, getters, rootState) => rootState.config.features[FeatureNames.filterViewReactions],
    isVisitScoreEnabled: (state, getters, rootState) => rootState.config.features[FeatureNames.visitScore],
    areImportFilesEnabled: (state, getters, rootState) => rootState.config.features[FeatureNames.importFiles],
  },
};
