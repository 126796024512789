/**
 * These are the names of the cookies used in the application.
 * @type {{}}
 */
export default {
  currentPage: 'posmo_current_page',
  accessToken: 'posmo',
  userRole: 'posmo_userRole',
  displayMode: 'posmo_display_mode',
};
