export default class SocketClient {
  createSocket(mode) {
    console.log('Creating socket client.');
    if (window.location.host.indexOf('localhost') > -1) {
      // Dev mode
      // noinspection JSValidateTypes
      this.socket = io(`http://localhost:3000?token=${window.vuexStore.state.accessToken}&mode=${mode}`);
    } else {
      // noinspection JSValidateTypes
      this.socket = io(`?token=${window.vuexStore.state.accessToken}&mode=${mode}`);
    }

    this.socket.on('connect_error', () => {
      console.log('[Socket] Socket connect error.');
    });

    this.socket.on('reconnect', () => {
      console.log('[Socket] Socket reconnected.');
    });
  }

  destroySocket() {
    this.socket.close();
    this.socket = undefined;
  }

  on(eventName, handler) {
    this.socket.on(eventName, (data) => {
      console.debug(`Received event: '${eventName}'`, data);
      handler(data);
      globalVue.$emit(eventName, data);
    });
  }
}
