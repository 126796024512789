import { httpGet } from '@/classes/httpHelper';
import ReportLocations from '@/enums/report-locations';

// noinspection JSUnusedGlobalSymbols
export default {
  namespaced: true,
  state: {
    availableReportTypes: [],
    selectedForReport: {},
    reportUIVisible: false,
  },
  mutations: {
    reset(state) {
      state.reportUIVisible = false;
      state.selectedForReport = {};
      state.availableReportTypes = [];
    },
    toggleReportsVisible(state) {
      state.reportUIVisible = !state.reportUIVisible;
    },
    selectVisitForReport(state, visit) {
      // TODO: TEST VUE3
      state.selectedForReport[visit.id] = visit;
      // Vue.set(state.selectedForReport, visit.id, visit);
    },
    setSelectedForReport(state, list) {
      state.selectedForReport = list;
    },
    deselectVisitForReport(state, visitId) {
      if (!(visitId in state.selectedForReport)) return;
      // TODO: TEST VUE3
      delete state.selectedForReport[visitId];
      // Vue.delete(state.selectedForReport, visitId);
    },
    deselectAllVisits(state) {
      state.selectedForReport = {};
    },
    setAvailableReportTypes(state, reportTypes) {
      state.availableReportTypes = reportTypes;
    },
  },
  getters: {
    getReportTypesForFeed(state) {
      return state.availableReportTypes.filter((rt) => rt.applicationOptions.includes(ReportLocations.feed));
    },
    getReportTypesForItemDetails(state) {
      return state.availableReportTypes.filter((rt) => rt.applicationOptions.includes(ReportLocations.itemDetails));
    },
    getReportTypesForGallery(state) {
      return state.availableReportTypes.filter((rt) => rt.applicationOptions.includes(ReportLocations.gallery));
    },
  },
  actions: {
    selectAllVisits({ commit, rootState }) {
      const list = {};
      rootState.galleryItems.forEach((item) => {
        list[item.id] = item;
      });
      commit('setSelectedForReport', list);
    },
    async loadReportTypes({ commit }) {
      try {
        const reportTypes = await httpGet('reporttypes');
        commit('setAvailableReportTypes', reportTypes);
      } catch (e) {
        console.error('Could not load report types. ');
        console.error(e);
      }
    },
  },
};
