/**
 * This enumerates the different types of data that can be shown in a data table.
 * It also corresponds to the names used in the vuex caching module, except that not all
 * data types are currently cached.
 */
export default {
  campaigns: 'campaigns',
  chains: 'chains',
  channels: 'channels',
  customers: 'customers',
  districts: 'districts',
  products: 'products',
  users: 'users',
};
